const initialState = {
  acceptAll: true, // TODO Enable when cookie preference is ready to be deployed
  // rejectOthers: false,
  showCookiePreferenceBanner: true,
};

export const cookiePreferenceReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACCEPT_ALL: {
      localStorage.setItem("acceptsCookie", true);
      return {
        ...state,
        acceptAll: true,
        showCookiePreferenceBanner: false,
      };
    }
    case SET_REJECT_OTHERS: {
      localStorage.setItem("acceptsCookie", false);
      return {
        ...state,
        acceptAll: false,
        showCookiePreferenceBanner: false,
      };
    }
    case SET_SHOW_COOKIE_PREFERENCE_BANNER:
      return { ...state, showCookiePreferenceBanner: action.payload };
    default:
      return state;
  }
};

// action creators
export const setAcceptAll = (flag) => {
  return {
    type: SET_ACCEPT_ALL,
    payload: flag,
  };
};

export const setRejectOthers = (flag) => {
  return {
    type: SET_REJECT_OTHERS,
    payload: flag,
  };
};

export const setShowCookiePreferenceBanner = (flag) => {
  return {
    type: SET_SHOW_COOKIE_PREFERENCE_BANNER,
    payload: flag,
  };
};

// selectors
export const getAcceptAll = (state) => state.cookiePreference.acceptAll;
// export const getRejectOthers = (state) => state.cookiePreference.acceptAll;
export const getShowCookiePreferenceBanner = (state) =>
  state.cookiePreference.showCookiePreferenceBanner;

// Action Types
export const SET_ACCEPT_ALL = "cookiePreference/acceptAll";
export const SET_REJECT_OTHERS = "cookiePreference/rejectOthers";
export const SET_SHOW_COOKIE_PREFERENCE_BANNER = "cookiePreference/showBanner";
