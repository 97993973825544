import LogRocket from "logrocket";
import { AnalyticsInstance } from "analytics";
import cookies from "js-cookie";

type IPluginProps = {
  instance: AnalyticsInstance;
  config: IPluginConfig;
};

// @todo add params from https://docs.logrocket.com/reference#console
type IPluginConfig = {
  appId: string;
};

const defaultConfig = {
  enabled: true,
};

export default function logrocket(pluginConfig: IPluginConfig) {
  return {
    name: "logrocket-analytics",
    config: {
      ...defaultConfig,
      ...pluginConfig,
    },

    initialize: (plugin: IPluginProps) => {
      // Initialization is happening on the page/a/[slug]/index because we want LogRocket only for fundraisers at the moment
      return;
    },

    loaded: () => {
      // LogRocket loaded via package, no need to check for window existence.
      return true;
    },

    identify: (props) => {
      // Identify is happening on the page/a/[slug]/index because we want LogRocket only for fundraisers at the moment
      return;
    },

    track: ({ payload }) => {
      try {
        if (LogRocket?.sessionURL?.includes("Must call LogRocket.init()")) {
          // Abort tracking if LogRocket is not initialized
          console.warn("LogRocket not initialized, aborting...");
          return;
        }
        const { event, properties } = payload || {};
        if (properties?.items) {
          properties.items = JSON.stringify(properties.items);
        }

        LogRocket.track(event, properties);
      } catch (e) {
        console.error("LogRocket tracking failed", e);
      }
    },
  };
}
