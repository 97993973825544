import { createSelector } from "reselect";
import cookieCutter from "cookie-cutter";
import { isEnableGDPR } from "../components/Utils";

const initialState = {
  showConsentAsk: false,
  consentError: false,
  consentValue: null,
  chooseConsent: false,
  showCookieConsentBanner: false,
};

export const consentReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHECK_CONSENT_REQUIRED: {
      const showConsentAsk = isEnableGDPR(action.payload?.value);
      return { ...state, showConsentAsk };
    }
    case SET_SHOW_CONSENT_ASK: {
      return { ...state, showConsentAsk: action.payload.value };
    }
    case SET_CONSENT_ERROR: {
      return { ...state, consentError: action.payload };
    }
    case SET_CONSENT: {
      return {
        ...state,
        consentError: false,
        consentValue: action.payload,
      };
    }
    case CHOOSE_CONSENT: {
      return {
        ...state,
        chooseConsent: action.payload,
      };
    }
    case SET_BANNERS: {
      const { showCookieConsentBanner } = action.payload;
      return {
        ...state,
        showCookieConsentBanner,
      };
    }
    case CLOSE_COOKIE_CONSENT_BANNER: {
      return {
        ...state,
        showCookieConsentBanner: false,
      };
    }
    case RESET_CONSENT: {
      return {
        ...state,
        showConsentAsk: false,
        consentError: false,
        consentValue: null,
      };
    }
    default:
      return state;
  }
};

// action creators
export const setConsent = (consent) => {
  return {
    type: SET_CONSENT,
    payload: consent,
  };
};

export const setChooseConsent = () => {
  return {
    type: CHOOSE_CONSENT,
    payload: true,
  };
};

export const setShowConsentAsk = (payload) => {
  return {
    type: SET_SHOW_CONSENT_ASK,
    payload: payload,
  };
};

export const setConsentError = (payload) => {
  return {
    type: SET_CONSENT_ERROR,
    payload: payload,
  };
};

export const resetConsent = () => {
  return {
    type: RESET_CONSENT,
    payload: null,
  };
};

export function getBanners() {
  return (dispatch, getState) => {
    const cookieConsentStatus = cookieCutter.get("cookieconsent_status");
    const {
      consent: { showConsentAsk },
    } = getState();
    const showCookieConsentBanner =
      showConsentAsk &&
      (!cookieConsentStatus || cookieConsentStatus !== "dismiss");

    dispatch({
      type: SET_BANNERS,
      payload: {
        showCookieConsentBanner,
      },
    });
  };
}

export function closeCookieConsentBanner() {
  return (dispatch, getState) => {
    let expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + 1);
    cookieCutter.set("cookieconsent_status", "dismiss", {
      expiration: expirationDate,
    });
    dispatch({
      type: CLOSE_COOKIE_CONSENT_BANNER,
    });
  };
}

// selectors
export const selectConsent = (state) => state.consent;
export const getShowConsentAsk = createSelector(
  selectConsent,
  ({ showConsentAsk }) => showConsentAsk
);
export const getConsentError = createSelector(
  selectConsent,
  ({ consentError }) => consentError
);
export const getConsentValue = createSelector(
  selectConsent,
  ({ consentValue }) => consentValue
);
export const getConsent = createSelector(
  getShowConsentAsk,
  getConsentValue,
  (showConsentAsk, consent) => {
    if (showConsentAsk) return consent;
    return false;
  }
);
export const getChooseConsent = (state) => state.consent.chooseConsent;
export const selectIsConsentValid = createSelector(
  getShowConsentAsk,
  getConsentValue,
  (showConsentAsk, consent) => {
    if (showConsentAsk && consent === null) return false;
    return true;
  }
);
export const selectShowCookieConsentBanner = createSelector(
  selectConsent,
  ({ showCookieConsentBanner }) => showCookieConsentBanner
);

// Action Types
export const CHECK_CONSENT_REQUIRED = "consent/checkIfConsentRequired";
export const SET_SHOW_CONSENT_ASK = "consent/setShowConsentAsk";
export const SET_CONSENT_ERROR = "consent/setConsentError";
export const SET_CONSENT = "consent/setConsent";
export const CLOSE_COOKIE_CONSENT_BANNER = "consent/closeCookieConsentBanner";
export const SET_BANNERS = "consent/setBanners";
export const RESET_CONSENT = "consent/resetConsent";
export const CHOOSE_CONSENT = "consent/chooseConsent";
