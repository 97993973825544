import cookieCutter from "cookie-cutter";

const pixelNotLoadedError = "Facebook pixel does not exist on the page";

/**
 *
 * @param {Object[]} events - The Facebook events to be tracked.
 */
export const logEventsToFacebook = (events) => {
  events.forEach((event) => {
    const { eventName, payload } = event;
    logEventToFacebook(eventName, payload);
  });
};

export const logEventToFacebook = (eventName, ...payload) => {
  try {
    if (payload && window.fbq) {
      logPixelEvent(eventName, ...payload);
    } else {
      console.warn(pixelNotLoadedError);
    }
  } catch (error) {
    console.error("Error sending tracking event:", error);
  }
};

export const logCustomEventToFacebook = (eventName, data) => {
  try {
    if (data && window.fbq) {
      window.fbq(
        "trackCustom",
        eventName,
        { ...data },
        { eventID: getUniqueId() }
      );
    }
  } catch (error) {
    console.error("Error sending tracking event:", error);
  }
};

const logPixelEvent = (eventName, data) => {
  if (data) {
    window.fbq("track", eventName, { ...data }, { eventID: getUniqueId() });
  }
};

export const getUniqueId = () => {
  const uniqueId = cookieCutter.get("unique_id");
  if (!uniqueId) {
    console.warn("Unique id not found");
  }
  return uniqueId;
};

export const CONTENT_TYPE = "product";
export const PRODUCT_CATALOG_ID = 445876772724152;
