export const getSignatureGoal = (currentSignatures) => {
  // List of goals in order of increasing significance
  const GOALS = [
    100, 200, 300, 400, 500, 1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000,
    9000, 10000, 15000, 20000, 25000, 50000, 75000, 100000, 150000, 200000,
    250000, 300000, 500000, 750000, 1000000, 1500000, 2000000, 5000000, 7500000,
    10000000, 15000000, 20000000, 50000000,
  ];
  const goalCount = GOALS.find((goal) => goal > currentSignatures) || 100; // Return closest goal to currentSignatures
  return goalCount;
};

const GDPRcountries = [
  "NL",
  "LU",
  "CH",
  "IS",
  "EE",
  "LT",
  "HU",
  "BE",
  "PT",
  "GB",
  "SK",
  "HR",
  "CZ",
  "IT",
  "FI",
  "PL",
  "MT",
  "SI",
  "RO",
  "BG",
  "SE",
  "CY",
  "DK",
  "FR",
  "LI",
  "IE",
  "ES",
  "GR",
  "LV",
  "NO",
];

export const doubleOptInCountries = ["AT", "DE"];

export const isEnableGDPR = (selectedCountry) => {
  return GDPRcountries.includes(selectedCountry);
};
export const isDoubleOptInCountry = (selectedCountry) => {
  return doubleOptInCountries.includes(selectedCountry);
};

export const handleFollowUpPage = (
  follow_up_url,
  currentFollowUpTemplate,
  supportedFollowUpTemplateTitles
) => {
  const NEXT_PUBLIC_CHAMPAIGN_API_HOST =
    process.env.NEXT_PUBLIC_CHAMPAIGN_API_HOST;

  const { pathname, origin, search } = window.location;

  const path = pathname.split("/a")[1];
  let redirectUrl = `${NEXT_PUBLIC_CHAMPAIGN_API_HOST}/a${path}/follow-up${search}`;

  if (supportedFollowUpTemplateTitles.includes(currentFollowUpTemplate)) {
    redirectUrl = follow_up_url
      ? `${origin}${follow_up_url}`
      : `${origin}${pathname}/follow-up${search}`;
  } else {
    redirectUrl = follow_up_url
      ? `${NEXT_PUBLIC_CHAMPAIGN_API_HOST}${follow_up_url}`
      : redirectUrl;
  }
  window.location.href = redirectUrl;
};

export const makeCancelable = (promise) => {
  let hasCanceled_ = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then((val) =>
      hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)
    );
    promise.catch((error) =>
      hasCanceled_ ? reject({ isCanceled: true }) : reject(error)
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true;
    },
  };
};

export const importRetry = async (importFn, retries = 2, interval = 1000) => {
  try {
    return await importFn();
  } catch (error) {
    if (retries) {
      await wait(interval);
      return importRetry(importFn, retries - 1, interval);
    } else {
      throw new Error(error);
    }
  }
};

function wait(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
