// Performs the same postal code validation that prosecco does

const ZIPCODES_REGEX = {
  US: /^\d{5}([ \-]\d{4})?$/,
};

const MAX_LENGTH = 9;

export const validatePostalCode = (postalCode, countryCode = null) => {
  const errors = [];

  function validateCountryFormat() {
    if (countryCode && ZIPCODES_REGEX[countryCode]) {
      if (!ZIPCODES_REGEX[countryCode].test(postalCode)) {
        errors.push("Invalid postal code");
      }
    }
  }

  function validateCharacters() {
    if (!/^[a-zA-Z\d\s\-]*$/.test(postalCode)) {
      errors.push("Postal code contains invalid characters");
    }
  }

  function validateLength() {
    const strippedPostal = postalCode.replace(/[^a-zA-Z\d]/g, "");
    if (strippedPostal.length > MAX_LENGTH) {
      errors.push("Postal code is too long");
    }
  }

  validateCountryFormat();
  validateCharacters();
  validateLength();

  return errors.length === 0;
};
