import cookieCutter from "cookie-cutter";

export const isCookiePresent = (key) => {
  const cookie = cookieCutter.get(key);
  return cookie?.length > 0;
};

export const clearCookie = (key) => {
  document.cookie = `${key} = ; Domain=.eko.org; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

export const MEMBER_DETAILS_KEY = "member_details";
export const PAYMENT_METHODS_KEY = "payment_methods";
export const MEMBER_ID_KEY = "member_id";
