import Cookies from "js-cookie";
import { GA4_EXPERIMENT_VIEW } from "../components/TrackingScripts/helpers";
import { GrowthBook } from "@growthbook/growthbook-react";
import {
  BrowserCookieStickyBucketService,
  setPolyfills,
} from "@growthbook/growthbook";
import analytics from "../lib/analytics";
import { Crypto } from "@peculiar/webcrypto";
import { isLiveEnv } from "../lib/environment";

const crypto = new Crypto();

const growthbookClientKey = process.env.NEXT_PUBLIC_GROWTHBOOK_CLIENT_KEY;
const growthbookDecryptionKey =
  process.env.NEXT_PUBLIC_GROWTHBOOK_DECRYPTION_KEY;

setPolyfills({
  SubtleCrypto: crypto.subtle,
});

export const growthbookClient = new GrowthBook({
  apiHost: "https://cdn.growthbook.io",
  clientKey: growthbookClientKey,
  enableDevMode: !isLiveEnv,
  subscribeToChanges: true,
  decryptionKey: growthbookDecryptionKey,
  stickyBucketService: new BrowserCookieStickyBucketService({
    jsCookie: Cookies,
  }),
  trackingCallback: (experiment, result) => {
    analytics.track(GA4_EXPERIMENT_VIEW, {
      experiment_id: experiment.key,
      variation_id: result.key,
    });
  },
});

async function loadFeatures() {
  await growthbookClient.loadFeatures({ autoRefresh: true });
}

export const getFeatureValue = (
  feature: string,
  defaultValue: any
): string | object => {
  const loadFeaturesAsync = async () => await loadFeatures();
  loadFeaturesAsync();
  const features = growthbookClient.getFeatureValue(feature, defaultValue);
  return features;
};
