import { NextSeo } from "next-seo";
import Head from "next/head";
import { LogoColor } from "../../types/components/logo";

const PageHead = ({
  title,
  slug,
  image,
  description,
  language,
  datePublished,
  dateModified,
}) => {
  const SITE_DOMAIN = process.env.NEXT_PUBLIC_SITE_DOMAIN;
  description = description
    ? description
    : "Ekō is a global movement of consumers, investors, and workers all around the world, standing together to hold corporations accountable for their actions and forge a new, sustainable and just path for our global economy. It's not going to be fast or easy. But if enough of us come together, we can make a real difference.";
  const handle = "@Eko_Movement";
  const aboutPage =
    language === "en"
      ? "https://eko.org/about"
      : `https://eko.org/${language}/about`;
  const defaultImage =
    "https://champaign-assets-production.s3.us-west-2.amazonaws.com/unique/Eko_homepage_header.png"; // replace with your default image URL

  const imageSrc = image?.original?.path
    ? `${process.env.NEXT_PUBLIC_CHAMPAIGN_API_HOST}${image.original.path}`
    : defaultImage;

  const url = `${SITE_DOMAIN}/a/${slug}`;
  const siteName = "Ekō";

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "NewsArticle",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": url,
    },
    headline: title,
    image: [imageSrc],
    datePublished: datePublished,
    dateModified: dateModified,
    author: [
      {
        "@type": "Organization",
        name: "Ekō",
        url: aboutPage,
      },
    ],
    publisher: {
      "@type": "Organization",
      name: siteName,
      logo: {
        "@type": "ImageObject",
        url: LogoColor.Purple,
      },
    },
  };

  const languageCode = {
    en: "en_US",
    es: "es_MX",
    fr: "fr_FR",
    de: "de_DE",
    nl: "nl_NL",
    pt: "pt_PT",
    ar: "ar_AR",
  };

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="title" content={title} />
        <meta
          name="twitter:card"
          content={image ? "summary_large_image" : "summary"}
        />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta name="description" content={description} />
        <meta property="og:url" content={url} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={imageSrc} />
        <meta property="og:site_name" content={siteName} />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content={language} />
        <meta
          property="og:locale:alternate"
          content={language ? languageCode[language] : "en_US"}
        />
        <meta property="fb:app_id" content="514456635236116" />
        <meta name="twitter:site" content={handle} />
        <meta name="twitter:creator" content={handle} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={imageSrc} />
        <meta
          name="twitter:domain"
          content={`${
            language === "en"
              ? "https://eko.org/"
              : `https://eko.org/${language}`
          }`}
        />

        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}
        />
      </Head>
      <NextSeo
        additionalLinkTags={[
          {
            rel: "icon",
            href: "https://champaign-assets-production.s3.us-west-2.amazonaws.com/unique/favico.ico",
            type: "image/x-icon",
          },
          {
            rel: "apple-touch-icon",
            href: "https://champaign-assets-production.s3.us-west-2.amazonaws.com/unique/favicon-avatar.png",
            type: "image/png",
            sizes: "32x32",
          },
          {
            rel: "mask-icon",
            href: "https://champaign-assets-production.s3.us-west-2.amazonaws.com/unique/favico.svg",
            type: "image/svg+xml",
          },
          {
            rel: "preconnect",
            href: "https://cdn.growthbook.io",
            crossOrigin: "true",
          },
          {
            rel: "preconnect",
            href: "https://www.googletagmanager.com",
            crossOrigin: "true",
          },
          {
            rel: "preconnect",
            href: "https://www.google.com",
            crossOrigin: "true",
          },
          {
            rel: "preconnect",
            href: "https://sumofus.imgix.net",
            crossOrigin: "true",
          },
          {
            rel: "preconnect",
            href: "https://o142675.ingest.sentry.io",
            crossOrigin: "true",
          },
        ]}
      />
    </>
  );
};

export default PageHead;
