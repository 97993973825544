export enum LogoColor {
  White = "https://champaign-assets-production.s3.us-west-2.amazonaws.com/unique/Eko_Logo_White.svg",
  Black = "https://champaign-assets-production.s3.us-west-2.amazonaws.com/unique/Eko_Logo_Black.svg",
  Mint = "https://champaign-assets-production.s3.us-west-2.amazonaws.com/unique/Eko_Logo_Mint.svg",
  Purple = "https://champaign-assets-production.s3.us-west-2.amazonaws.com/unique/Eko_Logo_Purple.svg",
}
export interface LogoProps {
  width: number;
  height: number;
  color: LogoColor;
  className?: string;
}

export const defaultClassName: string = "lg:mt-0 lg:ms-0 mt-2 ms-2";
