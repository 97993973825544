import { debounce } from "lodash";

const debouncedNotify = debounce((notify) => notify());
const shouldBatch = (action) => action.meta && action.meta.batch;

/**
 * This redux middleware will defer subscriptions when we have
 * a batch indicating in the meta
 */
const reduxMiddleware = (notifyStateChange) => {
  return () => (next) => (action) => {
    if (!action || !action.type) {
      return;
    }
    const resolved = next(action);

    /**
     * If we are not batching,
     * then use the default behavior of redux
     */
    if (notifyStateChange.notify && !shouldBatch(action)) {
      notifyStateChange.notify();
    } else {
      /**
       * If we are batching, then debounce to
       * limit our notifications to connected components
       */
      debouncedNotify(notifyStateChange.notify);
    }

    return resolved;
  };
};
export default reduxMiddleware;
