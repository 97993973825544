/**
 * Helper methods to handle external errored API calls
 */

const genericError = "Unknown error";

const logErrorMessage = (response, responseBody, params = {}) => {
  const { statusText, status = 500, url: endpoint } = response || {};
  const statusCodeText = statusText ? statusText : genericError;

  // TODO When switching to pulpo make sure to send all the error messages in the same attribute
  // let flatErrors = Object.entries(responseBody?.errors).map((error) => {
  //   return Object.values(error).join(",");
  // });

  console.error(
    JSON.stringify({
      severity: "error",
      message: `Call to API failed with statusCode: ${status} (${statusCodeText})`,
      errors: JSON.stringify(responseBody),
      endpoint,
      params,
    })
  );
};

const logException = (exception) => {
  const { message = genericError, status = 500 } = exception || {};

  console.error(
    JSON.stringify({
      message: `Call to API failed with Exception:${message} and statusCode:${status}`,
      exception,
    })
  );
};

const formatErrorResponse = (error) => {
  const { immediate_redonation, message } = error || {};
  if (error && Array.isArray(error.errors)) {
    return {
      errors: error.errors,
      success: false,
      ...(immediate_redonation && { immediate_redonation }),
      ...(message && { message }),
    };
  } else {
    let errors = error && error.errors ? [error.errors] : [error];
    return { errors, success: false };
  }
};

export const getErrorMessage = async (response, params = {}) => {
  if (!response) return formatErrorResponse(genericError);

  const contentType = response.headers.get("content-type");
  let responseBody = {};

  if (contentType && contentType.indexOf("application/json") !== -1) {
    responseBody = await response.json();
  }

  logErrorMessage(response, responseBody, params);

  return formatErrorResponse(responseBody);
};

export const handleException = (ex) => {
  logException(ex);
  const error = ex.message || genericError;
  return formatErrorResponse(error);
};
