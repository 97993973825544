import { AnalyticsInstance } from "analytics";

type IPluginProps = {
  instance: AnalyticsInstance;
  config: IPluginConfig;
};

// @todo add params from https://docs.logrocket.com/reference#console
type IPluginConfig = {
  appId: string;
};

const defaultConfig = {
  enabled: true,
};

// This does nothing, but it's here to avoid loading errors while the browser env is ready to use LogRocket
export default function logrocket(pluginConfig: IPluginConfig) {
  return {
    name: "logrocket-analytics",
    config: {
      ...defaultConfig,
      ...pluginConfig,
    },

    initialize: (plugin: IPluginProps) => {
      return;
    },

    loaded: () => {
      return true;
    },

    identify: (props) => {
      return;
    },

    track: ({ payload }) => {
      return;
    },
  };
}
