import "tailwindcss/tailwind.css";
import "../styles/main.scss";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { GrowthBookProvider } from "@growthbook/growthbook-react";
import { growthbookClient } from "../utils/growthbookClient";

import { store, persistor } from "../store";
import { measurementId } from "../components/TrackingScripts/LogGA";
import { NextIntlClientProvider } from "next-intl";
import PageHead from "../components/PageHead";
import { useMediaQuery } from "react-responsive";
import Cookies from "js-cookie";
import { getMemberCurrencyByCountry } from "../api";

const NEXT_PUBLIC_RECAPTCHA_SITE_KEY =
  process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY;

function MyApp({ Component, pageProps }) {
  const { isReady, query } = useRouter();
  const { dir, recaptchaSiteKey = NEXT_PUBLIC_RECAPTCHA_SITE_KEY } = pageProps;
  const [gaClientId, setGaClientId] = useState(null);
  const [userId, setUserId] = useState(null);

  const isMobile = useMediaQuery({ maxDeviceWidth: 767 });

  useEffect(() => {
    let isMounted = true;
    if (isMounted && isReady && !gaClientId) {
      gtag("get", measurementId, "client_id", (cId) => {
        setGaClientId(cId);
      });
    }
    return () => {
      isMounted = false;
    };
  }, [isReady, gaClientId]);

  useEffect(() => {
    let isMounted = true;
    const { akid } = query;
    if (isMounted && isReady && !userId && akid) {
      const akidSplit = akid.split(".") || [];
      const userId = akidSplit.length === 2 ? akidSplit[1] : null;
      setUserId(userId);
    }
    return () => {
      isMounted = false;
    };
  }, [isReady, userId, query]);

  useEffect(() => {
    // Load features async
    let isMounted = true;

    const initGrowthbook = async () => {
      if (isMounted && isReady && gaClientId) {
        const geoData = await getMemberCurrencyByCountry();
        growthbookClient.setAttributes({
          id: gaClientId,
          clientId: gaClientId,
          prontoId: Cookies.get("pronto_id"),
          url: window.location.href,
          language: pageProps?.language || "en",
          slug: pageProps?.slug || "",
          isMobile: isMobile,
          hasSavedPaymentMethod: Cookies.get("payment_methods") ? true : false,
          country: geoData?.country || "",
          isPetition:
            pageProps?.template_name?.toLowerCase().includes("petition") ??
            false,
        });
      }

      if (isMounted && isReady) {
        growthbookClient.init({ autoRefresh: true });
      }
    };
    initGrowthbook();

    return () => {
      isMounted = false;
    };
  }, [isReady, userId, gaClientId]);

  useEffect(() => {
    document.body.dir = dir;
  }, [dir]);

  return (
    <>
      <PageHead
        title={pageProps.title}
        description={
          pageProps.content?.length > 200
            ? pageProps.content
                .replace(/<[^>]*>/g, "")
                .replace(/&nbsp;/g, " ")
                .replace(/\r?\n|\r/g, " ")
                .substring(0, 200)
            : pageProps.content
        }
        slug={pageProps.slug}
        image={pageProps.image}
        language={pageProps.language}
        datePublished={pageProps.created_at}
        dateModified={pageProps.updated_at}
      />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <GrowthBookProvider growthbook={growthbookClient}>
            <GoogleReCaptchaProvider
              reCaptchaKey={recaptchaSiteKey}
              useEnterprise={true}
              scriptProps={{
                async: true,
                defer: false,
                appendTo: "head",
                nonce: undefined,
              }}
            >
              <NextIntlClientProvider
                messages={pageProps?.messages}
                locale={pageProps?.language || "en"}
              >
                <Component {...pageProps} />
              </NextIntlClientProvider>
            </GoogleReCaptchaProvider>
          </GrowthBookProvider>
        </PersistGate>
      </Provider>
    </>
  );
}

export default MyApp;
