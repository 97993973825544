import { batch } from "react-redux";

// Limits React re-render and redux subscription events
export function batchActions(dispatch, actions = []) {
  batch(() => {
    // See ./store/lib/redux-middleware.js
    actions.forEach((action) => {
      if (typeof action !== "function") {
        dispatch({
          ...action,
          meta: {
            ...action.meta,
            batch: true,
          },
        });
      } else {
        dispatch(action);
      }
    });
  });
}
