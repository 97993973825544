import { useRouter } from "next/router";
import { createSelector } from "reselect";

import { batchActions } from "../lib/redux-utils";
import {
  CHECK_CONSENT_REQUIRED,
  getConsent,
  getBanners,
  getShowConsentAsk,
} from "./ConsentReducer";
import {
  resetPaymentTypes,
  setDonationFormId,
  setForm,
  setPaymentMethods,
} from "./DonationReducer";
import { getPageId, setPageId, setPageSlug, setPageTitle } from "./PageReducer";

const initialState = {
  country: null,
  submitting: false,
  fields: {},
  countries_list: [],
  queryStringParams: {},
  form_id: null,
  cta_completed: false,
  socialShare: { opted: null, completed: false },
  scrollToDonateCompleted: false,
  show_double_opt_in_banner: false,
  errors: [],
};

export const actionFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SHOW_DOUBLE_OPT_IN_BANNER:
      return { ...state, show_double_opt_in_banner: action.payload };
    case SET_FORM_ID: {
      const router = useRouter();
      const {
        akid,
        referring_akid,
        source,
        referrer_id,
        rid,
        bucket,
        mailing_id,
      } = router.query;
      const queryStringParams = {
        akid,
        referring_akid,
        source,
        referrer_id,
        rid,
        bucket,
        mailing_id,
      };
      return { ...state, queryStringParams, form_id: action.payload };
    }
    case SET_CTA_COMPLETED:
      return { ...state, cta_completed: action.payload };
    case SET_SHARED_ON_SOCIAL_MEDIA:
      return { ...state, socialShare: action.payload };
    case SET_SCROLL_DONATION_COMPLETED:
      return { ...state, scrollToDonateCompleted: action.payload };
    case SET_COUNTRIES_LIST: {
      const countries = Object.keys(action.payload).map((key, index) => ({
        value: key,
        label: action.payload[key],
      }));

      return { ...state, countries_list: countries };
    }
    case SET_SUBMITTING: {
      return { ...state, submitting: action.payload };
    }
    case SET_COUNTRY: {
      return {
        ...state,
        country: action.payload,
        fields: { ...state.fields, country: action.payload?.value },
      };
    }
    case SET_FIELD: {
      const { name, value } = action.payload;
      return { ...state, fields: { ...state.fields, [name]: value } };
    }
    case SET_FIELDS: {
      return { ...state, fields: { ...state.fields, ...action.payload } };
    }
    case RESET_FORM: {
      return { ...state, fields: {} };
    }
    case SET_ERRORS: {
      return { ...state, errors: action.payload };
    }
    default:
      return state;
  }
};

// action creators
export const setCountriesList = (list) => {
  return {
    type: SET_COUNTRIES_LIST,
    payload: list,
  };
};

export const setCountry = (country) => {
  return {
    type: SET_COUNTRY,
    payload: country,
  };
};

export const setPetitionFormId = (id) => {
  return {
    type: SET_FORM_ID,
    payload: id,
  };
};

export const setShowDoubleOptInBanner = (value) => {
  return {
    type: SET_SHOW_DOUBLE_OPT_IN_BANNER,
    payload: value,
  };
};

export const setSharedOnSocialMedia = (payload) => {
  return {
    type: SET_SHARED_ON_SOCIAL_MEDIA,
    payload,
  };
};

export const setScrollToDonateCompleted = (completed) => {
  return {
    type: SET_SCROLL_DONATION_COMPLETED,
    payload: completed,
  };
};

export const setFormValues = (values) => {
  return {
    type: SET_FIELDS,
    payload: values,
  };
};

export const setField = (event) => {
  const { target } = event;

  switch (target.type) {
    case "checkbox": {
      return {
        type: SET_FIELD,
        payload: {
          name: event.target.name,
          value: event.target.checked,
        },
      };
    }
    default: {
      return {
        type: SET_FIELD,
        payload: {
          name: event.target.name,
          value: event.target.value,
        },
      };
    }
  }
};

export const countryChanged = (country = "US") => {
  return (dispatch, getState) => {
    const state = getState();
    if (country?.length == 2) {
      const countriesList = state.action_form.countries_list;
      country = countriesList.filter(
        (countryItem) => countryItem.value === country
      )[0];
    }

    const actions = [
      resetPaymentTypes(),
      { type: SET_COUNTRY, payload: country },
      { type: CHECK_CONSENT_REQUIRED, payload: country },
      setPaymentMethods(),
      getBanners(),
    ];

    batchActions(dispatch, actions);
  };
};

export function setPageDetails({ id, slug, title, fundraiser, petition }) {
  return (dispatch, getState) => {
    let actions = [];

    actions.push(setPageId(id));
    actions.push(setPageSlug(slug));
    actions.push(setPageTitle(title));

    // Initialize petition props - petition form id
    actions.push(setPetitionFormId(petition.form_id));

    // Initialize donation props - donation form id, donation form elements
    if (fundraiser) {
      actions.push(setDonationFormId(fundraiser.form_id));
      actions.push(setForm(fundraiser.form));
    }

    batchActions(dispatch, actions);
  };
}

export const resetForm = () => {
  return {
    type: RESET_FORM,
    payload: {},
  };
};

// selectors
export const getShowDoubleOptInBanner = (state) =>
  state.action_form.show_double_opt_in_banner;
export const getCountry = (state) => state.action_form.country;
export const getQueryStringParams = (state) =>
  state.action_form.queryStringParams;
export const getFields = (state) => state.action_form.fields;
export const getCountriesList = (state) => state.action_form.countries_list;
export const getSubmitting = (state) => state.action_form.submitting;
export const getFormId = (state) => state.action_form.form_id;
export const getCtaCompleted = (state) => state.action_form.cta_completed;
export const selectSocialShare = (state) => state.action_form.socialShare;
export const selectErrors = (state) => state.action_form.errors;
export const selectScrollToDonateCompleted = (state) =>
  state.action_form.scrollToDonateCompleted;
export const selectFacebookLeadData = createSelector(
  getConsent,
  getCountry,
  getFields,
  getPageId,
  (consent, country, fields, page_id) => {
    if (!consent) return null;

    const { postal, action_phone_number } = fields;

    return {
      phone: action_phone_number,
      postal,
      country: country.value,
      page_id,
    };
  }
);

export const selectFormData = createSelector(
  getCountry,
  getFields,
  getFormId,
  getPageId,
  getQueryStringParams,
  getShowConsentAsk,
  getConsent,
  (country, fields, formId, pageId, queryString, showConsentAsk, consent) => {
    const payload = {
      ...queryString,
      ...fields,
      pageId,
      country: country?.value,
      form_id: formId,
      action_pronto: 1,
      ...(showConsentAsk &&
        consent !== null && {
          consented: consent ? 1 : 0,
        }),
    };

    return payload;
  }
);

// Action Types
export const SET_FORM_ID = "actionForm/setFormId";
export const SET_COUNTRIES_LIST = "actionForm/setCountriesList";
export const SET_COUNTRY = "actionForm/setCountry";
export const SET_FIELD = "actionForm/setField";
export const SET_FIELDS = "actionForm/setFields";
export const SET_SUBMITTING = "actionForm/setSubmitting";
export const SET_CTA_COMPLETED = "actionForm/setCtaCompleted";
export const SET_SHOW_DOUBLE_OPT_IN_BANNER =
  "actionForm/setShowDoubleOptInBanner";
export const SET_SHARED_ON_SOCIAL_MEDIA = "actionForm/setSharedOnSocialMedia";
export const SET_SCROLL_DONATION_COMPLETED =
  "actionForm/setScrollToDonateCompleted";
export const SET_ERRORS = "actionForm/setErrors";
export const RESET_FORM = "actionForm/resetForm";
