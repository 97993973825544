import { getFeatureValue } from "../utils/growthbookClient";
declare global {
  interface Window {
    ApplePaySession: any;
  }
}
const SUPPORTED_DIRECT_DEBIT_COUNTRIES = [
  "GB",
  "NL",
  "FR",
  "DE",
  "AT",
  "ES",
  "AU",
  "BE",
  "CY",
  "EE",
  "IE",
  "IT",
  "LV",
  "LU",
  "MC",
  "PT",
  "SM",
  "SI",
];

const DEFAULT_TYPE_ORDER = [
  "applepay",
  "paypal",
  "card",
  "gocardless",
  "ideal",
];

const PAYPAL_NOT_SUPPORTED_CURRENCIES = ["ARS"];
const DEFAULT_TYPE = "card";

export const orderPaymentMethods = (
  paymentMethods: string[],
  order: string[]
): string[] => {
  const orderedMethods = order.filter((method) =>
    paymentMethods.includes(method)
  );
  const additionalMethods = paymentMethods.filter(
    (method) => !order.includes(method)
  );
  const result = [...orderedMethods, ...additionalMethods];
  return Array.from(new Set(result));
};

export const isDirectDebitSupported = (country: string): boolean =>
  SUPPORTED_DIRECT_DEBIT_COUNTRIES.includes(country);

export const isPaypalSupported = (currency: string): boolean =>
  !PAYPAL_NOT_SUPPORTED_CURRENCIES.includes(currency);

export const isApplePaySupported = (): boolean => {
  const applePayEnabled = getFeatureValue("apple-pay", false);
  if (!applePayEnabled) return false;

  return (
    window.ApplePaySession &&
    window.ApplePaySession.supportsVersion(3) &&
    window.ApplePaySession.canMakePayments()
  );
};

export const isGooglePaySupported = (): boolean => {
  const googlePayEnabled = getFeatureValue("google-pay", false);
  if (!googlePayEnabled) return false;
  return true;
};

export const getLocalPaymentMethods = (
  currency: string,
  country: string,
  donationAmount: number
): string[] => {
  const localPaymentMethods: string[] = [];

  if (currency === "EUR") {
    // IDEAL
    if (country === "NL") localPaymentMethods.push("ideal");
  }

  return localPaymentMethods;
};

export const fetchPaymentTypes = (
  currency: string,
  country: string,
  amount: number,
  controlOrder: string[]
): string[] => {
  const orderingData = getFeatureValue("payment_type_ordering", {});
  let ordering = orderingData[currency]
    ? orderingData[currency]
    : DEFAULT_TYPE_ORDER;

  let paymentTypes: string[] = [DEFAULT_TYPE];

  if (isGooglePaySupported()) paymentTypes.push("googlepay");

  if (isPaypalSupported(currency)) paymentTypes.push("paypal");

  if (isDirectDebitSupported(country)) paymentTypes.push("gocardless");

  if (isApplePaySupported()) paymentTypes.push("applepay");

  const localPaymentMethods = getLocalPaymentMethods(currency, country, amount);

  const paymentTypesSet = new Set([...paymentTypes, ...localPaymentMethods]);

  paymentTypes = Array.from(
    orderPaymentMethods(Array.from(paymentTypesSet), ordering)
  );
  return paymentTypes;
};
